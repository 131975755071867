.headerBar {
	background-color: #425665;
	border-bottom: solid 1px black;
	display: flex;
	padding: 5px;
}
.logoContainer {
	color: white;
}
.addArchive {
	position: absolute;
	right: 30px;
	padding: 0px 7px 4px 7px;
	background-color: #7493ab;
	border-radius: 5px;
	margin-top: -2px;
	color: white;
	font-weight: 600;
	-webkit-user-select: none;
	-webkit-user-drag: none;
	-moz-user-select: none;
	-o-user-select: none;
	-ms-user-select: none;
	user-select: none;
	user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	cursor: pointer;
}
.addArchiveContainer {
	width: 250px;
	margin: auto;
}
.formTitle {
	color: white;
	text-align: center;
	font-weight: bold;
}
form.addArchiveForm {
	background-color: #425665;
	padding: 10px;
	border-radius: 10px;
	margin-top: 5px;
	color: white;
}
label {
	font-size: 12px;
}
input {
	width: 97%;
	background-color: #2b3740;
	outline: none;
	border: none;
	margin: 5px 0;
	color: white;
}
input#archiveSubmit {
	color: white;
	border-radius: 3px;
	background-color: rgb(2, 73, 2);
}

.formButtons {
	display: flex;
}
.cancelButton {
	width: 100%;
	text-align: center;
	background-color: red;
	border-radius: 3px;
	margin: 0px 10px 0 0px;
	height: 17px;
	align-self: center;
	font-size: 12px;
	-webkit-user-select: none;
	-webkit-user-drag: none;
	-moz-user-select: none;
	-o-user-select: none;
	-ms-user-select: none;
	user-select: none;
	user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	cursor: pointer;
}
.archiveList {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	height: 100%;
}

.archiveEntry {
	margin: 10px auto;
	display: flex;
	flex-direction: column;
}
.archiveEntryTitle {
	width: 100%;
	margin: 0 auto;
	color: white;
	font-weight: bold;
}
.archiveEntryImage {
	max-width: 200px;
	width: 100%;
	max-height: 300px;
	background-color: gray;
}
html {
	background-color: #1e2a33;
}
.fullScreenScrim {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
    z-index: 1;
}

.archiveEntryFull {
	width: 60%;
	margin: 10% auto;
	text-align: center;
}
.archiveEntryImageLarge {
	max-width: 300px;
	width: 100%;
	background-color: gray;
}
.archiveEntryReviews {
	color: white;
	text-align: left;
}
.reviewName {
	font-size: 16px;
	font-weight: 800;
	margin-top: 10px;
}
.reviewEntry {
	display: flex;
	background-color: #1a1d21;
	padding: 10px;
	border-radius: 10px;
	margin-bottom: 5px;
}
.reviewNumber {
	margin: 0 5px;
	font-size: 30px;
	height: 100%;
	border-radius: 10px;
	padding: 5px;
}
.rank0 {
	background-color: rgb(255, 1, 1);
}
.rank1 {
	background-color: rgb(148, 0, 0);
}
.rank2 {
	background-color: rgb(248, 174, 37);
}
.rank3 {
	background-color: rgb(241, 227, 21);
}
.rank4 {
	background-color: rgb(188, 247, 53);
}
.rank5 {
	background-color: rgb(18, 221, 28);
}
.entryAverageScoreContainer {
    content: ' ';
    border-style: solid;
    border-width: 50px 50px 0 0;
    border-color: #000000 transparent transparent transparent;
    position: absolute;
}

span.entryAverageScore {
    position: absolute;
    color: white;
    font-weight: 500;
	margin-top: -45px;
    padding-left: 5px;
	width: 20px;
    text-align: center;
}